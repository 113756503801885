<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="7" lg="6" xl="5">
			<base-material-card
				icon="mdi-lock-question"
				color="secondary"
				v-bind="$attrs"
				v-on="$listeners"
				:disabled="formLoading"
			>
				<template v-slot:after-heading>
					<div align="center" class="ml-auto text-right">
						<h1 class="font-weight-light text--primary">
							¿Olvidó su contraseña?
						</h1>
					</div>
				</template>

				<v-col
					cols="12"
					class="px-0"
				>
					<v-divider />
				</v-col>

				<v-card-text align="center">
					<v-form v-if="showData" ref="EmailForm" onSubmit="return false;">
						<v-col cols="12" sm="8" md="8">
							<base-text-field v-if="showData" v-model="email" label="Correo Electrónico" counter :maxlength="person.emailMaxLength"  
							:rules="[commonValRules.required, commonValRules.email, personValidationRules.emailRules.counter]" required></base-text-field>
						</v-col>
					</v-form>
					<h3 class="font-weight-light" :class="{'red--text' : error}">
							<b>{{ message }}</b>
					</h3>
				</v-card-text>

				<template v-slot:actions>
					<v-spacer></v-spacer>
					<v-btn
						v-if="showData"
						color="primary"
						depressed
						@click="send()"
						:loading="formLoading"
					>
						Recuperar contraseña
					</v-btn>
    		</template>
			</base-material-card>
		</v-col>
		
		<base-loader />
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import PersonData from '@/mixins/person-data'

export default {
	mixins: [MainExternalData, PersonData],

	data() {
		return {
			email: '',
			error: false,
			message: '',
			showData: true,
			formLoading: false,
		}
	},

	computed: {
    ...mapGetters(['activeSession'])
	},
	
	created() {
		if (this.activeSession) {
			this.$router.push({ name: 'Home' });
		}
	},

	methods: {
		async send() {
			let me=this;
			if (!me.$refs.EmailForm.validate()) {
				return;
			}

			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				let request={
					'email': me.email,
				};
				await me.getBaseResponseWithoutHeaders('api/Account/RecoverPassword', request, true).then(data => {
					if (data.success) {
						me.message='Se envió el correo electrónico de recuperación satisfactoriamente. Por favor revise su bandeja de entrada y la bandeja de correo no deseado.'
						me.showData=false
					} else {
						me.error=true
						me.message=data.message
						me.showData=false
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
    },
	},
}
</script>